<template>
  <b-tabs>

<template  >
	<b-tab 
	  lazy
	>
	  <template #title>
		<span>Clients</span>
	  </template>
  
	  <client-table  />
	</b-tab>
  </template>
  
  <b-tab 
	  lazy
	 
	>
	  <template #title>
		<span>Testing</span>
	  </template>
  
	  <client-table  :isTest="true"/>
	</b-tab>
  
  <!-- New Tab Button (Using tabs-end slot) -->
  <template #tabs-end>
	<div class="ml-auto">
  
	</div>
  </template>
  </b-tabs>
</template>


<script>
import {
  BTabs, BTab, BButton, BCol, BRow,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ClientTable from './ClientTable.vue';
export default {
  components: {
    BTabs,
    BTab,
    BButton,
    BCol,
    BRow,
    ClientTable
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      testProject:true
    }
  },
  created() {
    
  },
  methods: {

  },
}
</script>
  